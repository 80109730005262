* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Architects Daughter", cursive, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

:root {
  --white: #fff;
  --black: #333;
  --active-color: rgb(176, 89, 255);
  --border-radius: 4rem;
}

html {
  font-size: 62.5%;
}

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: auto;
  background-color: #171717;
  color: var(--white);
}

body,
textarea {
  -webkit-font-smoothing: antialiased;
  font-family: "Architects Daughter", cursive, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 2rem;
}

input,
select,
textarea {
  width: 100%;
  padding: 1rem 1rem;
  margin: 0.2rem 0;
  display: inline-block;
  border: 0.3rem solid #ccc;
  border-radius: 0.4rem;
  box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: 800;
  resize: none;
}
label {
  font-size: 1.5rem;
  font-weight: 500;
}

p {
  text-align: justify;
}

h2 {
  text-align: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.grid {
  height: 100%;
  width: 92.6%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;
  align-items: center;
  display: none;
}

.grid.active {
  display: grid;
}

.grid-item {
  display: flex;
}

.projects-grid {
  height: 80%;
  width: 100rem;
  margin: auto;
  display: none;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
}

.projects-grid.active {
  display: grid;
}

.projects-grid-int {
  height: 80%;
  width: 100%;
  margin: auto;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
}

.projects-grid-int.active {
  display: flex;
}

.gif {
  width: 100%;
  animation: loadingSpin 10s infinite linear;
}

.form-btn {
  width: 20rem;
  height: 4rem;
  font-size: 1.75rem;
  background-color: var(--active-color);
  font-weight: 600;
  border: 0.4rem solid var(--active-color);
  border-radius: 0.4rem;
  cursor: pointer;
  box-shadow: 0.6rem 0.6rem 0.5rem 0rem rgb(71, 71, 71),
    -0.1rem -0.1rem 0.5rem 0rem rgb(51, 51, 51);
  padding: 0;
}

.form-btn:active {
  transform: scale(1.2);
}

.loading-btn {
  width: 10rem;
  height: 3rem;
  font-size: 1.75rem;
  background-color: var(--active-color);
  font-weight: 600;
  border: 0.1rem solid var(--active-color);
  border-radius: 0.4rem;
  cursor: pointer;
  box-shadow: 0.6rem 0.6rem 0.5rem 0rem rgb(71, 71, 71),
    -0.1rem -0.1rem 0.5rem 0rem rgb(51, 51, 51);
  padding: 0;
  margin: 0;
}
.loading-btn:active {
  transform: scale(1.2);
}

.heading-container {
  width: 100%;
  height: auto;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-text {
  width: 100%;
  height: 17rem;
  padding: 1rem 1rem;
  margin: 0.2rem 0;
  border-radius: 0.4rem;
  box-sizing: border-box;
  font-size: 1.5rem;
}

.logo {
  width: 100%;
  background-color: transparent;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.form-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(95, 95, 95, 0.6);
  z-index: 4;
  align-items: center;
  justify-items: center;
  justify-content: center;
  display: flex;
}

.loading-content {
  width: 45rem;
  min-width: 45rem;
  height: 40rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.loading-logo-container {
  height: 60%;
  width: auto;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.loading-logo {
  height: 100%;
  width: 15rem;
  animation: loadingSpin 10s infinite linear;
  background-color: transparent;
  padding: 1rem 0 1rem 0;
}

@keyframes loadingSpin {
  to {
    transform: rotateY(360deg);
  }
}

.loading-text {
  width: 75%;
  height: 15%;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.tabs-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tab-links {
  width: 100%;
  padding: 0;
  margin: 0 auto 2rem;
  list-style: none;
  max-width: 40rem;
  display: flex;
  justify-content: space-between;
}

.tab {
  position: relative;
}

.tab a {
  text-decoration: none;
  color: var(--black);
}

.tab::before {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  border-radius: var(--border-radius);
}

.tab svg {
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  fill: var(--white);
  transition: fill 0.5s ease;
}

.tab.active::before {
  background-color: var(--active-color);
}

.tab span {
  font-weight: 800;
  margin-left: 1rem;
  transition: color 0.5s ease;
}

.tab.active span {
  color: black;
}

.tab.active svg {
  fill: black;
}

.tab a {
  padding: 1.6rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}
