@media only screen and (max-height: 790px) {
  .heading-container {
    margin-bottom: 1rem;
  }
  .container {
    overflow-y: scroll;
  }

  * {
    font-size: 2rem;
  }

  .heading {
    font-size: 2rem;
  }

  .grid-item {
    display: flex;
    max-width: 30rem;
    max-height: 30rem;
  }
  .tab a {
    padding: 0.5rem;
  }

  .form {
    gap: 0.2rem;
  }

  input,
  select,
  textarea,
  label,
  p {
    padding: 0rem 0rem;
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 515px) and (max-height: 1000px) {
  input,
  select,
  textarea,
  label,
  p {
    padding: 0rem 0rem;
    font-size: 1.4rem;
  }

  .heading-container {
    margin-bottom: 1rem;
  }
  * {
    font-size: 2rem;
  }

  .heading {
    font-size: 2rem;
  }

  .tab a {
    padding: 0.5rem;
  }

  .form {
    gap: 0.2rem;
    width: 100%;
  }

  .container {
    overflow-y: scroll;
  }

  .grid.active {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .grid {
    width: 100%;
  }

  .grid-item {
    max-width: 80%;
    width: 80%;
  }
  .tab-links {
    max-width: 80%;
    width: 80%;
  }
  .tab::before {
    width: 100%;
  }
}
